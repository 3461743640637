import React from 'react'
import Layout from '../components/layout'

export default function Career() {
  return (
    <Layout>

      <section id="one" className="main style1">
        <div className="grid-wrapper">
          <div className="col-12">

            <h1>Job Opening</h1>


            <h2>1. Staff Software Engineer</h2>

            <p>
              Lead the architecting, design and development of scalable, high
              performance, and reliable software components, systems, and
              applications, using object oriented programming language (such as
              Python, Go, Java, C++). Characterize and address complex problems
              related to the scalability, reliability, performance, and security of
              the software. Handle large-scale distributed storage and database
              systems (MySQL, Cassandra). Develop and execute test plans and
              automation processes. Mentor and train other team members on design
              techniques, and coding standards.
            </p>

            <p>
              Require: M.S. degree in Computer Science; 2 years’ experience in job
              offered or as Sr. Software Engineer; academic or experience background
              in data mining, machine learning, management information systems,
              computer network, information technology security, development of
              software systems scaling to millions of users with production-quality
              deployment and reliability, handling of distributed storage and
              database systems (MySQL, Cassandra), and software development using
              Python, Go, Java and C++. Will accept any suitable combination of
              education, training, or experience.
            </p>

            <p>
              Send resume to: Hyperconnect, 68 Willow Road, Menlo Park, CA 94025
            </p>


            <h2>2. Staff Software Engineer</h2>

            <p>
              Lead the architecting, design, development and testing of software components, systems, and applications
              with a focus on network and cloud security. Characterize and address complex problems related to the
              mobile/app/browser security. Mentor and train other team members on design techniques, and coding
              standards. Manage individual project priorities, deadlines and deliverables.

            </p>

            <p>
              Require: Master’s degree in Computer Science, Computer Engineering or Information Security Technology and
              Management. 2 years’ experience in job offered or as Senior Software Engineer. Academic or experience
              background include web app security performance, mobile security, browser security, cloud computing, and
              backend services to support mobile application process. Will accept any suitable combination of education,
              training, or experience.
            </p>

            <p>
              Work Site/send resume to: Hyperconnect, 68 Willow Road, Menlo Park, CA 94025
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
