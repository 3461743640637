import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <section id="footer">
                <ul className="copyright">
                    <li>Contact Us:  support@hyperconnect.co</li>
                    <li>HyperConnect &copy; 2020 All Rights Reserved</li>
                </ul>
            </section>
        )
    }
}

export default Footer
